@import "./assets/scss/_config.scss";
.login-register-alert {
    margin: 0 auto -2rem;
    padding: 2rem 0 0;    
}

.login-register {
    padding: 2rem 0;
}

.login-register__column:first-child {
    margin-bottom: 1rem;
}

.login-register__column .form__input {
    background-color: white;
}

.login-register__column .fields {
    flex-direction: column !important;
}

.login-register .label {
    display: inline-block !important;
}

.login-register .label:first-letter {
    text-transform: capitalize;
}

.login-register__column .form__item {
    width: 100% !important;
}

.login-register__column .form-block,
.login-register__column .card {
    margin: 0 !important;
    width: 100% !important;
    height: 100%;
}

.login-register__column .form-block .btn {
    width: auto;
}

@media (min-width: 992px) {
    .login-register {
        display: flex;
    }

    .login-register__column:first-child {
        margin: 0 1rem 0 0;
    }

    .login-register__column {
        flex: 1 1 auto;
        max-width: calc(50% - .5rem);
    }
}